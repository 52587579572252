<template>
  <div>
    <div class="content my-16">
      <h1 class="mb-10" v-text="$t('unsubscribe.unsubscribeHeader')"/>
      <div v-if="unsubscribed">
        <p class="mb-10" v-text="$t('unsubscribe.youHaveSuccessfullyUnsubscribed')"/>
        <v-btn dark :loading="loading" @click="confirm('resubscribe')" v-text="$t('unsubscribe.resubscribe')"/>
      </div>
      <div v-else>
        <p class="mb-10" v-text="$t('unsubscribe.pleaseConfirmThatYouWish')"/>
        <v-btn dark :loading="loading" @click="confirm('unsubscribe')" v-text="$t('unsubscribe.confirm')"/>
      </div>
    </div>
    <Footer/>
  </div>
</template>

<script>
import Vue from 'vue'

export default Vue.extend({
  name: "Unsubscribe",
  data() {
    return {
      loading: false,
      unsubscribed: false,
    }
  },
  methods: {
    confirm(action) {
      this.loading = true
      this.$http.post(`/emails/${action}/`,
        { encryption: this.$route.query.encryption })
        .then(() => {
          setTimeout(() => {
            this.loading = false
            this.unsubscribed = action === 'unsubscribe'
            if (this.$auth.user()) {
              this.$auth.fetch()
            }
          }, 1000)
        })
        .catch(() => {
          this.loading = false
        })
    },
  },
})
</script>
<style>
  .content {
    text-align: center;
  }
</style>
